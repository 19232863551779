import React from 'react'
import QRCode from "react-qr-code";
import './App.css';
import {Link, useParams} from 'react-router-dom'


function InvitationComponent() {
    let {regId} = useParams();
    return (<div className="container">
        <div className="testimonial-box">
            <div className="testimonial">
                <i className="fas fa-quote-right"></i>
                <span className="testimonial-text">Dear Bekhzod, The Family of It Park Uzbekistan Happy to invite you to our annvesary.</span>
                <div className="testimonial-user">
                    {/*<img src="https://randomuser.me/api/portraits/women/17.jpg" alt="user-img" className="user-img"></img>*/}
                    <div style={{background: 'white', padding: '16px'}}>
                        <QRCode value={regId} size={128}/>
                    </div>
                    <div className="user-info">
                        <span className="user-name">Your invitation ID is {regId}</span>
                    </div>
                </div>
            </div>
        </div>
        <Link to="/registration">Registration</Link>
    </div>)
}


export default InvitationComponent;
