import {
    Routes,
    Route,
    useParams
} from "react-router-dom";
import './App.css';
import RegistrationComponent from './RegistrationComponent';
import InvitationComponent from "./InvitationComponent";
import HomeComponent from "./HomeComponent";


function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<HomeComponent/>}/>
                <Route path="registration" element={<RegistrationComponent/>}/>
                <Route path="reg/:regId" element={<InvitationComponent/>}/>
            </Routes>
        </div>
    );
}

export default App;

