import React, {Component} from 'react'
import {Link} from "react-router-dom";


import './Home.css';

class HomeComponent extends Component {
    render() {
        return (
            <div className="container">
                <div className="masthead">
                    <h1>IT Park REWIND 2022</h1>
                </div>
                <div className="content">
                    <h2>Приветствуем!</h2>

                    <p>Спешим сообщить Вам радостную новость - нам уже 3 года! Приглашаем Вас разделить с нами успехи достигнутые за эти 3 года, а также хорошо провести время, в компании IT-комьюнити Узбекистана.
                        Праздник состоится 8 июля 2022 года в 11:00 на территории Аструм академии,по адресу:Yangibozor, Узбекистан
                        <Link to="https://www.google.com/maps/place/Astrum+-+%D0%98%D0%A2+%D0%90%D0%BA%D0%B0%D0%B4%D0%B5%D0%BC%D0%B8%D1%8F/@41.313742,69.5285532,17.54z/data=!4m5!3m4!1s0x0:0x5a794f7263a1e89a!8m2!3d41.3135419!4d69.5283904">(локация)</Link>.
                        Будем счастливы видеть Вас на нашем празднике!
                    </p>
                    <p><em>- С уважением Фарход Заирович</em></p>
                </div>
                <div>
                    <Link to="/registration">Registration</Link>
                </div>
                <div>
                    <p>ПРОГРАММА МЕРОПРИЯТИЯ:
                        <table width="150px" border="0">
                            <tr>
                                <td width="120px">11:00-13:00 </td>
                                <td>Церемония открытия,официальная часть</td>
                            </tr>
                            <tr>
                                <td>13:00-14:00 </td>
                                <td>Обед</td>
                            </tr>
                            <tr>
                                <td>14:10-15:00</td>
                                <td>Встреча по вопросам BPO, Министр ИКТ Шерзод Шерматов</td>
                            </tr>
                            <tr>
                                <td>15:00-16:00</td>
                                <td>Нетворкинг по направлениям</td>
                            </tr>
                            <tr>
                                <td>16:00-18:00</td>
                                <td>Развлекательная программа</td>
                            </tr>
                            <tr>
                                <td>18:00-22:00</td>
                                <td>Ужин</td>
                            </tr>
                        </table>
                    </p>
                </div>
                <div>
                    <table>
                        <tr>
                            <td className="content footer" align="center">
                                <p><a href="#">IT-Park</a>4, Tepamasjid str., Tashkent, Uzbekistan, 100164</p>
                                <p><a>по всем вопросам</a>+998 71 209 11 99</p>
                                <p><a href="mailto:">info@it-park.uz</a> | <a href="https://it-park.uz/">сайт</a></p>
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        )
    }
}

export default HomeComponent;
