import React, { Component } from 'react'
import { Link } from "react-router-dom";

class RegistrationComponent extends Component{

    constructor(props){
        super(props)
        this.state={
            firstName:'',
            lastName:'',
            company:'',
            emailId:'',
            phone:''
        }

        this.changeFirstNameHandler=this.changeFirstNameHandler.bind(this);
        this.changeLastNameHandler=this.changeLastNameHandler.bind(this);
        this.changeCompanyHandler=this.changeCompanyHandler.bind(this);
        this.changeEmailIdHandler=this.changeEmailIdHandler.bind(this);
        this.changePhoneHandler=this.changePhoneHandler.bind(this);
        this.saveStudent=this.saveStudent.bind(this);
    }

    changeFirstNameHandler=(event)=>{
        this.setState({firstName: event.target.value});
    }

    changeLastNameHandler=(event)=>{
        this.setState({lastName: event.target.value});
    }

    changeCompanyHandler=(event)=>{
        this.setState({company: event.target.value});
    }

    changeEmailIdHandler=(event)=>{
        this.setState({emailId: event.target.value});
    }

    changePhoneHandler=(event)=>{
        this.setState({phone: event.target.value});
    }

    saveStudent=(e)=>{
        e.preventDefault();
        let person={firstName: this.state.firstName,lastName: this.state.lastName,company: this.state.company,emailId: this.state.emailId, phone: this.state.phone};
        console.log('Person=>'+JSON.stringify(person));
    }

    cancel(){
        this.props.history.push();
    }

    render(){
        return(
            <div>

            <div className="container">
                <div className="row">
                    <br></br>
                    <h1 className="text-center">IT Park Annevesary Form</h1>
                    <br></br>
                    <div className="card col-md-6 offset-md-3 offset-md-3">
                        <div className="card-body">
                            <form>
                                <div className="form-group">
                                    <label>First Name:</label>
                                    <input placeholder="First Name" name="firstName" className="form-control"
                                    value={this.state.firstName} onChange={this.changeFirstNameHandler}/>
                                </div>
                                <div className="form-group">
                                    <label>Last Name:</label>
                                    <input placeholder="Last Name" name="lastName" className="form-control"
                                    value={this.state.lastName} onChange={this.changeLastNameHandler}/>
                                </div>

                                <div className="form-group">
                                    <label>Company:</label>
                                    <input placeholder="Company" name="company" className="form-control"
                                           value={this.state.company} onChange={this.changeCompanyHandler}/>
                                </div>

                                <div className="form-group">
                                    <label>Email:</label>
                                    <input placeholder="Email" name="emailId" className="form-control"
                                    value={this.state.emailId} onChange={this.changeEmailIdHandler}/>
                                </div>
                                <div className="form-group">
                                    <label>Address:</label>
                                    <input placeholder="Phone" name="phone" className="form-control"
                                    value={this.state.phone} onChange={this.changePhoneHandler}/>
                                </div>
                                <br></br>
                                <br></br>
                                <button className="btn btn-success" onClick={this.saveStudent}>Save</button>
                                <Link className="btn btn-success" to={`/reg/${this.state.phone}`}/>
                                <button className="btn btn-danger" onClick={this.cancel.bind} style={{marginLeft:"10px"}}>Cancel</button>
                                <Link to="/">home</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default RegistrationComponent
